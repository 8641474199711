<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay" [disabled]="!(authState | async)?.emailVerified">
      <ion-content>
        <ion-list id="inbox-list">
          <ion-list-header class="ion-margin-bottom">おーだーシステム</ion-list-header>
          <ion-menu-toggle auto-hide="false">
            @for (p of appPages; track p; let i = $index) {
              <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
                <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            }
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
