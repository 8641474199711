import { ActionReducerMap } from '@ngrx/store';
import { IUserStatus } from '@rdlabo/odss-mobile-api/src/shared/auth/auth.api';

import * as status from './status';

export type IReducers = {
  status: IUserStatus;
};
export const reducers: ActionReducerMap<IReducers> = {
  status: status.reducer,
};
