import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { GuardService } from '../../../shared/src/auth/guard.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'main/search/transaction',
    pathMatch: 'full',
  },
  { path: 'auth', loadChildren: () => import('../../../shared/src/auth/auth.routes').then((m) => m.routes) },
  {
    path: 'main/search',
    loadChildren: () => import('./search/search.routes').then((m) => m.routes),
    canActivate: [() => inject(GuardService).requireAuthorized()],
  },
  {
    path: 'main/settings',
    loadChildren: () => import('./settings/settings.routes').then((m) => m.routes),
    canActivate: [() => inject(GuardService).requireAuthorized()],
  },
  {
    path: 'main/access',
    loadChildren: () => import('./access/access.routes').then((m) => m.routes),
    canActivate: [() => inject(GuardService).requireAuthorized()],
  },
  {
    path: 'main/invoice',
    loadChildren: () => import('./invoice/invoice.routes').then((m) => m.routes),
    canActivate: [() => inject(GuardService).requireAuthorized()],
  },
  {
    path: 'main/estimate',
    loadChildren: () => import('./estimate/estimate.routes').then((m) => m.routes),
    canActivate: [() => inject(GuardService).requireAuthorized()],
  },
];
