import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/src/auth/auth.service';
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonRouterLink,
  IonRouterOutlet,
  IonSplitPane,
} from '@ionic/angular/standalone';
import { filter, Observable, Subscription, timer } from 'rxjs';
import { SettingsService } from './settings/settings.service';
import { Store } from '@ngrx/store';
import { IReducers } from '../reducer';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { actionStatus } from '../reducer/status';
import { environment } from '../../../mobile/src/environments/environment';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { HttpClient } from '@angular/common/http';

declare type User = import('firebase/auth').User;

@Component({
  selector: 'customer-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    RouterLinkActive,
    RouterLink,
    AsyncPipe,
    IonRouterLink,
    IonApp,
    IonSplitPane,
    IonMenu,
    IonContent,
    IonList,
    IonListHeader,
    IonMenuToggle,
    IonItem,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  public appPages = [
    { title: '取引検索', url: '/main/search/transaction', icon: 'mail' },
    { title: '請求書', url: '/main/invoice', icon: 'document' },
    { title: '見積書', url: 'main/estimate', icon: 'document' },
    { title: 'ユーザマネージャー', url: '/main/access', icon: 'people', allowAccess: ['admin'] },
    { title: '設定', url: '/main/settings', icon: 'settings' },
  ];

  public authState: Observable<User | null>;
  public authStateSubscription$: Subscription;
  public initSubscription$: Subscription;
  public statusSubscription$: Subscription;
  private readonly auth = inject(AuthService);
  private readonly settings = inject(SettingsService);
  private readonly store: Store<IReducers> = inject(Store);
  private readonly router = inject(Router);
  private readonly http = inject(HttpClient);

  constructor() {}

  ngOnInit() {
    this.authState = this.auth.getState();
    this.authStateSubscription$ = this.authState.subscribe((user) => {
      if (user?.emailVerified && this.statusSubscription$ === undefined) {
        this.statusSubscription$ = timer(0, 8000).subscribe(() => {
          // storeでアカウントの状態を共有
          this.settings.getStatus().then((status) => {
            this.appPages = this.appPages.filter((value) => {
              return (
                (!value.allowAccess?.length || value.allowAccess.includes(status.profile.permission)) &&
                (!value.title.includes('開発中') || status.profile.companyId === 1110)
              );
            });
            this.store.dispatch(actionStatus(status));
          });
        });

        this.initSubscription$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((params: any) => {
          this.http
            .post<void>(environment.api() + 'analytics', {
              route: params.url,
              type: 'customer',
            })
            .subscribe();

          FirebaseAnalytics.logEvent({
            name: params.url,
            params: {
              url: params.url,
            },
          });
        });
      }
    });
  }

  ngOnDestroy() {
    this.authStateSubscription$.unsubscribe();
    if (this.statusSubscription$) {
      this.statusSubscription$.unsubscribe();
    }
  }
}
